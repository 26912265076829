.meus-filmes {
  color: #fff;
  text-align: center;
  background-color: #000; 
  padding-top: 80px;
  width: 100%;
  min-width: 300px; 
  height: auto;
}

.meus-filmes h1 {
  font-size: 30px;
  color: #fff;
  text-align: center;
  justify-content: center ;
}

.meus-filmes .filmes-itens {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.meus-filmes .favoritos {
  width: 30%;
  margin-bottom: 2.5rem;
  background-color: #111;
  position: relative;
}

.meus-filmes .favoritos img {
  max-width: 100%;
  height: auto;
}

.meus-filmes .favoritos .rating {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #116fe0;
  padding: 5px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
}

.meus-filmes .favoritos .rating svg {
  margin-right: 5px;
  color: #f7d354;
}

.meus-filmes .favoritos h2 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.meus-filmes  button {
  background-color: #ff3c38;
  border: none;
  color: white;
  font-weight: bold;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5px;
}

.meus-filmes button:hover {
  background-color:#d30000;
}

.meus-filmes  li {
  list-style-type: none;
}

@media screen and (max-width: 768px) {
  .meus-filmes .favoritos {
    width: 165px;
  }

  .meus-filmes .favoritos .rating {
    left: 5px;
  }
}

