/* ............................Parte  detalhe do filme .......................................*/
.filme {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 100%;
    min-width: 300px;
}

.filme-backdrop {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: 0 35%;
    filter: brightness(0.50);
}

.filme-detalhe {
    align-items: center;
    width: 75%;
    display: flex;
    position: relative;
    bottom: 225px;
}

.filme-detalhe-esquerda {
    margin-right: 30px;
}

.filme-poster {
    width: 300px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.86) 0px 22px 40px 6px;
    margin-left: 150px;
}

.filme-detalhe-direita {
    color: white;
    display: flex;
    flex-direction: column;
    height: 450px;
    justify-content: space-between;
    margin-top: 0px;

}

.titulo {
    font-weight: 600;
    font-size: 3rem;
}

.avaliação {
    margin-left: 0px;
}

.fas {
    color: #f7d354;
}

.gênero {
    margin: 1.25rem 0;
}

.filme-gênero {
    padding: .5rem;
    border: 2px solid white;
    border-radius: 20px;
    margin-right: 1rem;

}

.sinopse {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    font-weight: 600;
    display: block;
    position: relative;
    align-items: center;
    color: white;
    margin-bottom: 10px;

}

.sinopse h3 {
    margin-bottom: 10px;
}


.sinopse-texto {
    display: block;
}

.movie__links {
    position: relative;
    bottom: 120px;
    display: flex;
    justify-content: space-between;
    width: 75%;
}

.area-button button {
    margin-right: 12px;
    margin-top: 14px;
    margin-left: 0;
    font-size: 20px;
    border: 0;
    outline: none;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.5s;
    background-color: #116fe0;
    color: white;
}

.area-button button:hover {
    background-color: white;
    color: #116fe0;
}

/* ..................Modelo de seta para os elencos e recomendados .......................................... */
.seta-esquerda,
.seta-direita {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.seta-direita {
    right: 0;
}

/*  ........................................Parte do elenco ..................................................... */
.elenco-conteudo {
    position: relative;
}

.elenco {
    overflow-x: hidden;
}

.elenco-item {
    text-align: center;
    margin-right: 20px;
    flex: 0 0 auto;
    width: 170px;
    border-radius: 8px;
}

.elenco h3 {
    font-size: 28px;
    margin-left: 30px;
    color: white;
    position: absolute;
    margin-top: -20px;
}

.elenco-lista {
    display: flex;
    padding: 30px;
}

.ator-card {
    color: white;
    padding: 10px;
}

.ator-imagem {
    width: 100%;
    height: 225px;
    object-fit: cover;
    border-radius: 8px;
}

.ator-nome {
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    color: white;
}

.ator-personagem {
    color: white;
}


/* ...................................... Parte do Trailer................................................................*/
.titulo-trailer h3 {
    font-size: 28px;
    text-align: initial;
    margin-left: 20px;
    color: white;
}

/* ................................................Parte dos recomendados................................................. */
.recomendados-conteudo {
    position: relative;
}

.recomendados {
    overflow-x: hidden;
    padding: 20px;
}

.recomendado-item {
    text-align: center;
    border-radius: 8px;
    padding: 10px;
}

.recomendados h3 {
    font-size: 28px;
    margin-bottom: 10px;
    color: white;
    position: absolute;
}

.recomendados-lista {
    display: flex;
    margin-top: 40px;
}

.recomendado-imagem {
    width: 300px;
    border-radius: 10px;
}

.recomendado-nome {
    margin-top: 10px;
    font-weight: bold;
    color: white;
}



/* ..........................Elenco e recomendados .........................*/
.nao-disponivel {
    color: #fff;
    margin: 30px;
}

/* ..........................Versão telas 1370 trailer .........................*/
@media (min-width: 1370px) {
    .trailer iframe {
        width: 100%;
        height: 600px;
        object-fit: cover;
        padding: 0 20px;
        margin-bottom: 30px;
    }
}

/*..................... Versão telas max 1300px e min 768px .....................*/
@media (min-width: 768px) and (max-width: 1300px) {

    /*..................... Parte detalhes do filme .....................*/
    .filme-poster {
        margin: 20px;
    }

    .titulo {
        font-size: 40px;
        margin: 0px -130px 5px 0px;
    }

    .avaliação {
        margin-left: 0px;
    }

    .filme-gênero {
        margin: 10px 4px;
        box-sizing: border-box;
        padding: 3px;
        display: inline-block;
    }

    .filme-detalhe-direita {
        height: auto;
    }

    .sinopse h3 {
        margin-bottom: 20px;
    }

    .sinopse-texto {
        margin: 0px -150px 5px 0px;
        line-height: 1.6;
        font-size: 16px;

    }

    .movie__links {
        flex-direction: column;
    }

    .area-button {
        display: flex;
    }

    /*..................... Parte recomendados .....................*/

    .recomendados-lista {
        margin-top: 50px;
    }
}

/*..................... Versão mobile .....................*/
@media (max-width: 767px) {

    /*..................... Detalhes do filme .....................*/
    .filme-backdrop {
        height: 250px;
    }

    .filme-detalhe {
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }

    .filme-detalhe-esquerda {
        margin-top: 50px;
        align-self: center;
        text-align: center;
    }

    .filme-poster {
        width: 200px;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
        align-self: center;
        text-align: center;
    }

    .filme-detalhe-direita {
        padding-left: 50px;
        display: block;
        min-width: 400px;
        height: auto;
        margin-left: 40px;
    }

    .titulo {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
    }

    .detalhe-importante {
        display: flex;
        flex-direction: row;
    }

    .avaliação {
        font-size: 25px;
        margin-bottom: 10px;
    }

    .avaliação i {
        color: #FF9800;
    }

    .minutos {
        font-size: 15px;
        margin-left: 15px;
        color: #116fe0;
        margin-top: 5px;
        font-weight: bold;
    }

    .lançamento {
        font-size: 15px;
        margin-left: 15px;
        color: #116fe0;
        margin-top: 5px;
        font-weight: bold;
    }

    .gênero {
        margin-top: 10px;
    }

    .filme-gênero {
        display: inline-block;
        padding: 5px 10px;
        margin-right: 10px;
        border-radius: 15px;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .sinopse {
        margin-top: 20px;
    }

    .sinopse-texto {
        line-height: 1.6;
    }

    .area-button {
        display: flex;
    }

    .area-button button {
        padding: 10px 20px;
        background-color: #116fe0;
        color: #fff;
        font-size: 16px;
    }

    /*..................... PARTE DO ELENCO .....................*/
    .elenco {
        overflow-y: hidden;
        overflow-x: initial;
    }

    .elenco h3 {
        text-align: initial;
        margin-left: 15px;
        margin-top: -30px;
    }

    .elenco-lista {
        margin-right: 10px;
        padding: 10px;
    }

    .ator-imagem {
        border-radius: 5px;
    }

    .ator-nome {
        font-size: 14px;
    }

    .ator-personagem {
        font-size: 12px;
    }

    /*..................... Parte do titulo do trailer .....................*/
    .titulo-trailer h3 {
        font-size: 28px;
        text-align: initial;
        margin-left: 15px;
    }

    /*..................... PARTE DOS RECOMENDADOS .....................*/
    .recomendados {
        overflow-y: hidden;
        overflow-x: initial;
        padding: 0px;
    }

    .recomendados h3 {
        margin-top: 20px;
        margin-left: 15px;
    }

    .recomendados-lista {
        margin-top: 50px;
    }

    .recomendado-imagem {
        width: 200px;
        border-radius: 10px;
        border-radius: 5px;
    }

    /*..................... Setas não aparecer na versão mobile .....................*/
    .elenco-conteudo .seta-esquerda,
    .seta-direita,
    .recomendados-conteudo .seta-esquerda,
    .seta-direita {
        display: none;
    }
}

/*..................... Versão mobile telas menores que 351.....................*/
@media (max-width: 351px) {

    /*..................... Detalhe do filme .....................*/
    .filme-poster {
        margin-left: 30px;
    }

    .filme-detalhe {
        align-self: center;
    }

    .filme-detalhe-direita {
        padding-left: 0;
        min-width: 100%;
        height: auto;
        margin: 10px;
    }

    .avaliação {
        margin: 0;
    }

    /*..................... Parte do elenco .....................*/
    .elenco-lista {
        margin: 0;
        padding: 10px 20px;
    }

    /*..................... Parte dos recomendados .....................*/
    .recomendado-imagem {
        width: 200px;
        border-radius: 10px;
    }
}