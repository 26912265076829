.footer {
    padding: 50px 0;
    color: white;
    position: relative;
    background-color: black;
    width: 100%;
    min-width: 300px;
}

.menuItems  {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
    .menuItem  {
        transition: all ease 0.3s;
        cursor: pointer;
        font-size: 12px;
    }
}

.menuItems a{
    text-decoration: none;
    color:#116fe0;
    &:hover {
        color: white;
    }
}


.infoTexto {
    font-size: 12px;
    line-height: 20px;
    opacity: 0.5;
    text-align: center;
    max-width: 1500px;
}

.centralizar-texto {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.rede-social {
    display: flex;
    justify-content: center;
    gap: 10px;
    .icon {
        color: #116fe0;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all ease 0.3s;
        &:hover {
            color: white;
        }
    }
}

#cinemas{
    padding-top: 50px; 
}

#populares,
#melhores,
#emBreve {
    scroll-margin-top: 50px; 
}

.roda-pé {
    display: flex;
    justify-content: center;
    align-items: center;
}