.container .title {
    margin-top: 80px;
    color: #fff;
    font-size: 50px;
    text-align: center;
  }
  
  .title .query-text {
    color: #116fe0;
  }
  
  .movies-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .movie-card {
    width: 30%; 
    color: #fff;
    margin-bottom: 2.5rem;
    background-color: #111;
    position: relative; 
  }
  
  .movie-card img {
    max-width: 100%;
    height: auto;
  }
  
  .movie-card .rating {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color:#116fe0;
    padding: 5px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
  }
  
  .movie-card .rating svg {
    margin-right: 5px;
    color: #f7d354;
  }
  
  .movie-card h2 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .movie-card .preferidos{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .movie-card .preferidos svg.heart-vermelho {
    color: red;
  }
  
  .movie-card .preferidos svg.heart-branco {
    color: white;
  }
  
  @media screen and (max-width: 768px) {
    .container .title {
      font-size: 30px;
    }
    
    .movie-card {
      width: 165px; 
    }

    .movie-card .rating {
      left:5px;
    }
  
}
 