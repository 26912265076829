.body{
  width: 100%;
  min-width: 300px;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.Imagem {
    height: 600px;
    position: relative;
}

.Imagem>img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    filter: brightness(0.7);
}

.texto-dentro-da-img {
    position: absolute;
    padding: 20px;
    padding-bottom: 50px;
    bottom: 0px;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    background-image: linear-gradient(rgb(0, 0, 0, 0), rgb(0, 0, 0, 1));
    opacity: 1;
    transition: opacity 0.3s;
}

.texto-dentro-da-img:hover {
    opacity: 1;
}

.titulo {
    font-weight: 900;
    font-size: 64px;
    margin-bottom: 6.4px;
    text-align: left;
}

.data-de-lançamento {
    font-size: 30px;
    margin-bottom: 16px;
}

.avaliação1 {
    margin-left: 8px;
}

.descrição {
    max-width: 1200px;
    font-style: italic;
    font-size: 16px;
    margin-bottom: 4px;
    display: flex;
    text-align: left;
    line-height: 1.4;
}

@media (max-width: 768px) {
    .Imagem {
        max-width: 800px;
        height: 250px;
        filter: brightness(0.70);
    }

    .texto-dentro-da-img {
        padding: 10px 10px;
    }

    .titulo h2 {
        font-size: 25px;
        text-align: left;
    }

    .gênero p {
        font-size: 15px;
    }

    .data-de-lançamento {
        font-size: 15px;
    }

    .avaliação1 {
        margin-left: 20px;
    }

   

}