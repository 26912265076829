.pagina-nao-encontrada{
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.pagina-nao-encontrada h1{
    font-size: 120px;
}

.pagina-nao-encontrada a{
    text-decoration: none;
    background-color: #116feb;
    color:white;
    padding: 10px;
    margin-top: 14px;
}