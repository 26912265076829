.header {

    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(3.5px);
    color: #0875fa;
    padding: 10px 40px;
    transition: transform 0.3s ease;
    position:fixed;
    transform: translateY(0);
    width: 100%;
    height: 50px;
    z-index: 2;
    top: 0;

    .logo {
        font-size: 24px;
        font-weight: bold;
        cursor: pointer;
        color: #0875fa;
        text-decoration: none;
    }

    .menuItems {
        list-style: none;
        display: none;
        align-items: center;
        position: fixed;
    }

    .mobileMenuItems {
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: pointer;
    }

    &.mobileView .menuItems {
        display: flex;
        position: fixed;
        top: 50px;
        left: 0;
        flex-direction: column;
        width: 100%;
        padding: 20px 0;
        background: rgba(0, 0, 0, 0.979);
    }

    &.mobileView .menuItem {
        font-size: 20px;
        height: auto;
        padding: 15px 20px;
        display: fixed;
        flex-direction: column;
    }

    &.mobileView .menuItem1{
        font-size: 20px;
        height: auto;
        padding: 15px 20px;
        display: fixed;
        cursor: pointer;
    }

    .searchBar {
        display: flex;
        position: fixed;
        padding: 10px;
        left: 0;
        right: 0;
        justify-content: center;
        top: 60px;
        animation: mobileMenu 0.3s ease forwards;
    }

    .searchInput {
        position: relative;
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 20px;
        padding: 5px 10px;
        width: 50%;
    }

    .searchInput input {
        width: 100%;
        font-size: 14px;
        border: none;
        outline: none;
        padding: 5px;
    }
}