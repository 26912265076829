.container {
  flex-wrap: nowrap;
  overflow-x: auto;
  background: black;
  width: 100%;
  min-width: 300px;
}

.seta-left,
.seta-right {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  margin-top: 170px;
}

.seta-right {
  right: 0;
}

.filmes-categorias {
  margin-bottom: 30px;
}

.lista-filmes {
  display: flex;
  overflow-x: auto;
  margin: 14px 0;
  padding: 10px;
  align-items: flex-start;
}

.lista-filmes::-webkit-scrollbar {
  display: none;
}

.filmes-categorias h2 {
  color: white;
  margin-left: 25px;
}


.lista-filmes article {
  flex: 0 0 auto;
  width: 300px;
  padding: 15px;
  border-radius: 4px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.lista-filmes article strong {
  color: white;
}

.lista-principal {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.conteudo-da-imagem {
  position:relative;
  
}

.imagem {
  width: 100%;
  max-height: 5000px;
  object-fit: cover;
  display: block;
  border-radius: 8px;
}

.conteudo-da-imagem .nota {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color:#116fe0;
  padding: 5px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
}

.conteudo-da-imagem .nota svg {
  margin-right: 5px;
  color: #f7d354;
}
.conteudo-da-imagem .preferidos{
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  
}

.heart-vermelho {
  color: red; /* ou a cor que você preferir */
}

.heart-branco {
  color: white; /* ou a cor que você preferir */
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}

@media (max-width: 768px) {
  .lista-filmes article {
    width: 200px;
  }

  .filmes-categorias .seta-left,
  .seta-right {
    display: none;
  }

  .imagem {
    width: 175px;
  }

}